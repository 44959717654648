.portfolio-page {
    padding-left: 100px;
    padding-right: 50px;
    width: calc(100% - 150px);
    position: initial;
    height: 100%;
    overflow: auto;

    h1.page-title {
        margin-left: 100px;
        margin-top: 100px;
    }

    .images-container {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        padding-bottom: 100px;
    }

    .image-box {
        position: relative;
        flex: 1 1 20%;
        height: 400px;
        
        overflow: hidden;
        border-radius: 10px;
        max-width: calc(25% - 10px);

        .portfolio-image {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .content {
            position: absolute;
            width: 100%;
            z-index: 3;
            padding: 10px 20px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.2) 0,
                rgba(0, 0, 0, 1)
            );
            bottom: -70px;
        }

        .title {
            margin-bottom: 0;
            margin-top: 0;
            color: #fff;
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            width: 100%;
            height: 100%;

        }

        .description {
            font-size: 14px;
            margin-bottom: 5px;
            color: #fff;
            font-weight: 700;
            width: 100%;
        }

        .btn {
            margin-top: 30px;
            margin-bottom: 10px;
            padding: 0 23px;
            margin: 12px;
            height: 40px;
            line-height: 34px;
            border: 2px solid rgba(8, 253, 216, 0.365);
            border-radius: 4px;
            font-size: 14px;
            color: #fff;
            background: transparent;
            text-transform: uppercase;
            font-weight: 700;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            cursor: pointer;
        }

        .btn:hover {
            transform: translateY(-3px);
            background: rgba(8, 253, 216, 0.365);
        }

        &:after {
            content: "";
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.365), #000);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            opacity: 0;
        }
          &:first-child {
              &:hover:after {
                content: 'Phast Phood uses a Rails front end and a Rails API backend. It offers recipes without the life story. Users can sign in with Google Oauth2 and save recipes.';
                font-size: 15px;
                font-weight: 500;
                color: black;
                padding: 5px;
                padding-top: 50px;

              }
          }
          &:nth-child(2) {
              &:hover:after {
                content: 'Sweater Weather is a Rails API.  Endpoints include forecast, book, and images for a given city. User sign up and login with API key verfication. And a roadtrip planning endpoint when given a start and destination.';
                font-size: 15px;
                font-weight: 500;
                color: black;
                padding-top: 50px;
                padding-right: 30px;
                padding-left: 2px;
              }
          }
          
          &:nth-child(3) {
              &:hover:after {
                content: 'PupTrainer is an app for anyone who wants to traini their dog.  The backend was built with Rails using a GraphQL API.  The API has several queries and endpoints for a seamless frontend.';
                font-size: 15px;
                font-weight: 500;
                color: black;
                padding-top: 50px;
                padding-right: 30px;
                padding-left: 2px;
              }
          }
          
          &:nth-child(4) {
              &:hover:after {
                content: 'Rancid Tomatillos is a React application for browsing and researching movies.  The application consumes a REST API for all movie data and uses custom animations.';
                font-size: 15px;
                font-weight: 500;
                color: rgb(255, 255, 255);
                padding-top: 50px;
                padding-right: 30px;
                padding-left: 2px;
              }
          }
          
          &:nth-child(5) {
              &:hover:after {
                content: 'This portfolio app was my first completed app using React or any language/framework other than Rails. A big thanks to Code with Sloba on Youtube for getting me started.';                
                font-size: 15px;
                font-weight: 500;
                color: black;
                padding-top: 50px;
                padding-right: 30px;
                padding-left: 2px;
              }
          }
          
          
          &:nth-child(6) {
              &:hover:after {
                content: 'Rails Engine is a business intelligence API.  Its endpoints offer data about merchants and items.  It also offers business intelligence endpoints such as top revenue earners through Active Record queries.';
                content: 'Viewing Party is a Rails App that allows users to search for movies, and create Viewing Parties with their friends.  Authentication/Verfication is handled with Bcrypt.';
                font-size: 15px;
                font-weight: 500;
                color: black;
                padding-top: 50px;
                padding-right: 30px;
                padding-left: 2px;              }
          }
          
          &:nth-child(7) {
              &:hover:after {
                content: 'This portfolio app was my first completed app using React or any language/framework other than Rails.  A big thanks to Code with Sloba on Youtube for getting me started.';
                content: 'Rails Engine is a business intelligence API.  Its endpoints offer data about merchants and items.  It also offers business intelligence endpoints such as top revenue earners through Active Record queries.';
                font-size: 15px;
                font-weight: 500;
                color: black;
                padding-top: 50px;
                padding-right: 30px;
                padding-left: 2px;
              }
          }
          &:nth-child(8) {
              &:hover:after {
                content: 'React Tetris is a fully functioning in browser game made with React.  The app features styled-components, and was a great excercise in logic with JavaScript/React.';
                font-size: 15px;
                font-weight: 500;
                color: rgb(227, 212, 212);
                padding-top: 50px;
                padding-right: 30px;
                padding-left: 2px;
              }
          }
          &:nth-child(9) {
              &:hover:after {
                content: 'React Meme Generator is an application for users to generate text over random meme images. The images come from a REST API.  Learning goals were to use props, manage state, and consume an API.';
                font-size: 15px;
                font-weight: 500;
                color: rgb(8, 8, 8);
                padding-top: 50px;
                padding-right: 30px;
                padding-left: 2px;
              }
          }
          &:nth-child(10) {
              &:hover:after {
                content: 'Night Writer is a project written entirely in Ruby using TDD.  Users can input a .txt file containing a message and Night Writer will conver that message to a "braile".  It can also translate braille into plain text.';
                font-size: 15px;
                font-weight: 500;
                color: black;
                padding-top: 50px;
                padding-right: 30px;
                padding-left: 2px;
              }
          }
          &:nth-child(11) {
              &:hover:after {
                content: 'This HackerNews Clone makes use of the HackerNews API.  Using vanilla JS this app allows users to view the top, new, show, and ask stories and comments from HackerNews as well as save stories to their favorites.';
                font-size: 15px;
                font-weight: 500;
                color: rgb(46, 30, 30);
                padding-top: 50px;
                padding-right: 30px;
                padding-left: 2px;
              }
          }
          &:nth-child(12) {
              &:hover:after {
                content: 'Using only vanilla JS and CSS users of Color Picker can input EITHER a Hex color code or a RGB value and lighten or darken the color in real time. Using only vanilla JS and CSS.';
                font-size: 15px;
                font-weight: 500;
                color: black;
                padding-top: 50px;
                padding-right: 35px;
                padding-left: 5px;
              }
          }
          &:nth-child(13) {
              &:hover:after {
                content: "This application is a clone of the popular Google Keep application.  It allows a user to save a 'sticky note' with a title and body, they can update, delete, and change the color of their notes in real time. Built only with vanilla JS and CSS.";
                font-size: 15px;
                font-weight: 500;
                color: black;
                padding-top: 50px;
                padding-right: 35px;
                padding-left: 5px;
              }
          }
          
        &:hover:after {
            content: "";
             opacity: 0.85;
        }

        &:hover .content {
            bottom: 0;
            background: transparent;
        }
    }

  .resume-button {
    color: rgb(8, 253, 216);
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid rgb(8, 253, 216);
    margin-top: 25px;
    float: right;
    animation: fadeInAnimation 1s 1.8s backwards;
    white-space: nowrap;
    background-color: #7b8083;
    transition: transform 0.1s ease-out;
    box-shadow: 0 5px 0 rgba(5, 191, 163, 0.993);
    border-radius: 6px;
    margin-right: 15%;

      &:hover {
          box-shadow: 0 0 0 rgba(5, 191, 163, 0.638);
          background: rgba(8, 253, 216, 0.123);
          color: rgb(255, 255, 255);
          transform: translateY(5px);
      }

  }


}