html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font: 300 11px/1.4 'Helvetica Neue', sans-serif;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #7b8083;
  /* background: #022c43; */
  /* overflow: hidden; */
  overflow-y: auto;
  display: block;
  
}