// $primary-color: #b0b0b0;
// $primary-color: #ffd700;
$primary-color: rgba(8, 253, 216);

// $primary-color: #6d1af4;

@import 'animate.css';
@import '~loaders.css/src/animations/pacman.scss';
@import 'loaders.css/src/animations/ball-clip-rotate-pulse.scss';
@import 'loaders.css/src/animations/ball-pulse-rise.scss';
@import 'loaders.css/src/animations/square-spin.scss';


@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/helvetica-neu.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'La Belle Aurore';
  src: url('./assets/fonts/LaBelleAurore.woff2') format('woff2'),
    url('./assets/fonts/LaBelleAurore.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Coolvetica';
  src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2'),
    url('./assets/fonts/CoolveticaRg-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

input,
textarea {
  font-family: 'Helvetica Neue', sans-serif;
}

.loader-hidden {
  display: none;
}

.loader-active {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  animation: fadeOut 1s 1s;
  animation-fill-mode: forwards;
}