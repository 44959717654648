.nav-bar {
  // background: #0e0d0d;
  background: #181818;
  width: 60px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 3;
  min-height: 500px;

  .logo {
    display: block;
    padding: 0px 0;

    img {
      display: block;
      margin: 10px auto;
      width: 60px;
      height: auto;
      padding-right: 4px;

      &.sub-logo {
        width: 50px;
      }
    }
  }

  nav {
    display: block;
    text-align: center;
    position: absolute;
    height: 210px;
    top: 50%;
    margin-top: -120px;
    width: 100%;

    a {
      font-size: 35px;
      color: rgba(8, 253, 216, 0.699);      
      // color: rgba(8, 253, 216, 0.407);
      display: block;
      line-height: 51px;
      height: 51px;
      position: relative;
      text-decoration: none;

      i {
        transition: all 0.3s ease-out;
      }

      &:hover {
        // color: rgb(255, 255, 255);
        color: rgb(255, 255, 255);
        // color: #ffd700;

        svg {
          opacity: 0;
        }

        &:after {
          opacity: 5;
        }
      }

      &:after {
        content: '';
        font-size: 9px;
        letter-spacing: 1.5px;
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
      }

      &:first-child {
        &:after {
          content: 'HOME';
        }
      }
    }

    a.about-link {
      &:after {
        content: 'ABOUT';
      }
    }

    a.contact-link {
      &:after {
        content: 'CONTACT';
      }
    }

    a.portfolio-link {
      &:after {
        content: 'PORTFOLIO';
      }
    }
    a.skills-link {
      &:after {
        content: 'SKILLS';
      }
    }

    a.active {
      svg {
      // color: rgba(8, 253, 216, 0.407); 
      color: rgba(8, 253, 216, 0.699);
      }
    }
  }

  ul {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: block;
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0;

    li {
      a {
        padding: 7px 0;
        font-size: 35px;
        display: block;
        font-size: 35px;
        line-height: 16px;
        // color: rgba(8, 253, 216, 0.407); 
        color: rgba(8, 253, 216, 0.699);
       
        &:hover {
        color: rgb(254, 254, 254); // color: #6d1af4;          // color: #ffd700;
        transition: all 0.3s ease-out;
        transform: scale3d(1.5, 1.2, 0.3);
        // background-color: rgb(254, 254, 254);
        }
      }
    }
  }
}
